import React, { FC } from 'react';

import FaqSchema from 'components/common/FaqSchema';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IFaqSectionProps } from './model';

const FaqSection: FC<IFaqSectionProps> = ({ id, faqTitle, faqList, faqDescription }) => (
  <div className="faq-section" id={id}>
    <DangerouslySetInnerHtml html={faqTitle} />
    {faqList.map(({ properties: { faqQuestion, faqAnswer } }) => (
      <div key={faqQuestion} className="faq-section__item">
        <DangerouslySetInnerHtml className="about-block__text" html={faqQuestion} />
        <DangerouslySetInnerHtml className="about-block__text" html={faqAnswer} />
      </div>
    ))}
    <DangerouslySetInnerHtml html={faqDescription} />
    <FaqSchema faqList={faqList} />
  </div>
);
export default FaqSection;
