import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { removeHTMLTags } from 'utils/helpers';

import { IFaqSchema } from './model';

const FaqSchema: FC<IFaqSchema> = ({ faqList }) => {
  const jsonLdSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [
        faqList.map(({ properties: { faqQuestion, faqAnswer } }) => ({
          '@type': 'Question',
          name: removeHTMLTags(faqQuestion),
          acceptedAnswer: {
            '@type': 'Answer',
            text: removeHTMLTags(faqAnswer),
          },
        })),
      ],
    }),
  };

  return <Helmet script={[jsonLdSchema]} />;
};

export default FaqSchema;
